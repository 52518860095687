
.floating-button {
    position: relative;
  
margin-left: 6px;
  font-size: 30px;
  
  color:#fff;
  
  cursor: pointer;
  
  }
  
  .floating-button:hover {
    color: #d808fc;
  }
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


.cartitems{
    margin-left: 5px;
    margin-right: 2px;
background-color:#fff;


border-bottom: 1px solid black;

}
.shadows
{
    
    box-shadow:0px 0px 5px 0px #9E9E9E;
   height: auto;
    
   
   padding-left: 14px;
   padding-right: 14px;
   
    
    text-align: center;
   
    

   }

   .shadowsforspecial
{
    
    box-shadow:0px 0px 5px 0px #eee5e5;
   height: 100%;
    
   
   padding-left: 14px;
   padding-right: 14px;
   padding-top: 2px;
    margin-left: 13px;
   margin-right: 10px;
    text-align: center;
   
    

   }
   .addCustomer{


    background-color:rgb(19, 140, 75);
    margin-right: -10px;
  padding: 2px;

color:white
   }
   .addCustomerBoxes{
    margin-right: -10px;
    margin-left: 4px;
margin-top: 4px;

   }
   .recentinvoice{
    margin-right: -10px;
    margin-left: 4px;
margin-top: 4px;
background-color:whitesmoke;

   }

  
   .cartBarHeading{

margin-left: -8px;
margin-right: 2px;
margin-top: 4px;
padding-top: 2px;
padding-bottom: 3px;
background-color: darkslategrey;
color: #fff;


   }

.cartBody{
    margin-left: 1px;
    margin-right: 1px;
background-color: #fff;
height: 230px;
overflow-y: auto;
overflow-x: hidden;
padding-bottom: 30px;
}

.cartBodyTotal{
    margin-left: -12px;
    margin-right: 2px;
    color: #fcf9f9;
background-color:purple;
height: 40px;
padding-top: 6px;
border-radius: 3px;
position: relative;

}

.cartButtonsRow1{
    margin-left: -10px;
    margin-right: 2px;
    margin-top: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #fff;
background-color:rgb(86, 128, 3);

border-radius: 3px;
}

.cartButtonsRow2{
    margin-left: -10px;
    margin-right: 2px;
    margin-top: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #fff;
background-color:red;

border-radius: 3px;
}

.cartButtonsRow3{
    margin-left: -10px;
    margin-right: 2px;
    margin-top: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #fff;
background-color:purple;

border-radius: 3px;
}

.cartButtonsRow4{
    margin-left: -10px;
    margin-right: 2px;
    margin-top: 4px;
    padding-top: 6px;
    padding-bottom: 4px;
    color:rgb(246, 239, 239);
   
background-color:rgb(12, 0, 12);
height: 40px;

border-radius: 3px;
}

.cartButtonsRow5{
    margin-left: -10px;
    margin-right: 2px;
    margin-top: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    color:black;
background-color:rba(255,255,255,0.8);

border-radius: 3px;
}

   .addItem{


    background-color:rgb(19, 140, 75);
    color: #fff;
    padding-top: 4px;
    margin-left: -8px;
    margin-right: 2px;
    padding-bottom: 4px;
    font-weight: 600;
    border-radius: 3px;
   }